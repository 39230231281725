import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

const TIMEOUT_DURATION_MS = 100;
const TYPICAL_COL_HEIGHT = 44;

@Directive({ selector: '[appVirtualTableScrollNormalizer]', standalone: true })
export class VirtualTableScrollNormalizerDirective implements AfterViewInit, OnChanges {
  @Input() tableMinHeight = 0;
  @Input() changeTrigger!: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.calculateTableHeight();
  }

  calculateTableHeight() {
    const tableRows = this.el.nativeElement.querySelectorAll('tbody tr');
    const tableHeader = this.el.nativeElement.querySelector('thead');
    let tableHeight = tableHeader.offsetHeight;
    const scrollPadding = 20;
    if (tableRows.length > 0) {
      tableRows.forEach((row: HTMLTableRowElement) => {
        tableHeight += row.offsetHeight || TYPICAL_COL_HEIGHT;
      });
      tableHeight += scrollPadding;
    } else {
      tableHeight = this.tableMinHeight + tableHeader.offsetHeight + scrollPadding;
    }
    this.renderer.setStyle(this.el.nativeElement, 'height', `${tableHeight}px`);
  }

  private recalculateTableHeightAfterDelay(): void {
    setTimeout(() => {
      this.calculateTableHeight();
    }, TIMEOUT_DURATION_MS);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeTrigger']) {
      this.recalculateTableHeightAfterDelay();
    }
  }
}
