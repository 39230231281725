<div class="h-[316px] flex justify-center items-center bg-ui-gray-50 text-ui-gray-100 relative">
  <div class="flex flex-col items-center justify-center">
    <i class="icon-upload-map text-5xl mb-1.5"></i>
    <span class="font-nunito font-semibold text-xs">{{ uploadText }}</span>
    <span class="font-nunito font-semibold text-xs">(SVG)</span>
  </div>
  <input
    class="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
    type="file"
    appUploadSpaceplan
    accept="image/svg+xml"
    [locationId]="locationId"
    [floorId]="floorId"
    [roomId]="roomId" />
</div>
