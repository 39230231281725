<ng-container *ngIf="client; else skeletonAnimation">
  <div class="flex flex-row items-center pb-4">
    <div class="flex-grow">
      <app-page-header>{{ client.friendlyName }}</app-page-header>
      <app-breadcrumbs [routeNames]="[client.friendlyName]"></app-breadcrumbs>
    </div>
    <div class="flex">
      @if (!client.isManagingClient) { @if (client.status === UserStatus.ARCHIVED) {
      <button class="btn-green btn-medium" (click)="unarchiveClient(client)">
        <i class="icon-restore text-3xl mr-2"></i>
        Restore
      </button>
      } @else { @if (client.status === UserStatus.ACTIVE) {
      <button appRestrictedEl class="btn-red btn-medium mr-3" (click)="deactivateClient(client)">
        <i class="icon-Deactivate text-3xl mr-2"></i>
        Deactivate
      </button>
      } @else if (client.status === UserStatus.DEACTIVATED) {
      <button appRestrictedEl class="btn-green btn-medium mr-3" (click)="activateClient(client)">
        <i class="icon-active text-3xl mr-2"></i>
        Activate
      </button>
      } }
      <button appRestrictedEl class="btn-black btn-medium mr-3" (click)="archiveClient(client)">
        <i class="icon-add-to-archive text-3xl mr-2"></i>
        Archive
      </button>
      }
    </div>
  </div>
  <mat-card class="card">
    <mat-card-title class="card-title-small pb-4">Basic Info</mat-card-title>
    <app-main-input-form
      label="Client name"
      controlName="friendlyName"
      formItemType="text"
      placeholder="Client Name"
      [disabled]="client.isManagingClient"
      [value]="client.friendlyName"
      (updateData)="updateClientData($event, client)" />
    <div class="flex flex-row pb-5">
      <label class="input-main-label">Client Id</label>
      <div
        class="border font-nunito font-normal flex items-center h-10 px-4 classic-form-item-wrapper rounded bg-ui-gray-50 text-sm"
        [class]="client.isManagingClient ? 'text-ui-gray-100' : 'text-ui-dark'">
        {{ client.id }}
      </div>
    </div>
    <div class="flex flex-row pb-5">
      <label class="input-main-label">Client Status</label>
      <div class="font-bold border flex items-center h-10 px-4 classic-form-item-wrapper rounded bg-ui-gray-50">
        <app-user-status [status]="client.status" fontSize="0.875rem" />
      </div>
    </div>
    <app-main-input-form
      [isRequired]="false"
      label="Ticketing Client Id"
      controlName="ticketingClientId"
      formItemType="text"
      placeholder="Ticketing Client Id"
      [disabled]="client.isManagingClient"
      [value]="client.tags['ticketingClientId']"
      (updateData)="updateClientData($event, client)" />
    <app-main-input-form
      [isRequired]="false"
      label="Description"
      controlName="description"
      formItemType="text"
      placeholder="Description"
      [disabled]="client.isManagingClient"
      [value]="client.description"
      (updateData)="updateClientData($event, client)" />
    <app-main-input-form
      label="Phone Number"
      [isRequired]="false"
      controlName="phoneNumber"
      formItemType="text"
      [applyPhoneMask]="true"
      [phoneMask]="phoneMask()"
      [phoneMaskExpression]="phoneMaskExpression()"
      [hasPrefix]="true"
      [placeholder]="phoneMask()"
      [disabled]="client.isManagingClient"
      [value]="client.tags['phoneNumber']"
      (updateData)="updateClientData($event, client)">
      <div class="flex justify-between">
        <mat-form-field class="input-classic max-w-[150px] mr-2">
          <mat-select
            (selectionChange)="updateCountryCode($event, client)"
            [value]="client.tags['countryCode'] || DEFAULT_COUNTRY.code">
            @for (country of COUNTRY_LIST | sort:'asc':'code'; track country.code) {
            <mat-option [value]="country.code">{{ country.code }} ({{ country.phoneCode }})</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </app-main-input-form>
    <app-main-input-form
      [isRequired]="false"
      label="Website"
      controlName="website"
      formItemType="text"
      placeholder="Website"
      [disabled]="client.isManagingClient"
      [value]="client.tags['website']"
      (updateData)="updateClientData($event, client)" />
    <mat-card-title class="card-title-small my-4">Session Timeout</mat-card-title>
    <app-slider
      [disabled]="client.isManagingClient"
      [value]="client.clientUserSettings.userSessionTimeoutMinutes"
      (valueChanged)="updateTimeout($event, client)" />
  </mat-card>

  <div class="flex justify-between items-center my-3">
    <mat-button-toggle-group [(ngModel)]="detailsType" class="main-toggle-group" aria-label="type of reports">
      <mat-button-toggle class="border-ui-green-light" value="sites">
        <mat-icon matIconPrefix *ngIf="detailsType === 'sites'">check</mat-icon>
        Sites
      </mat-button-toggle>
      <mat-button-toggle class="border-ui-green-light" value="users">
        <mat-icon matIconPrefix *ngIf="detailsType === 'users'">check</mat-icon>
        Users
      </mat-button-toggle>
    </mat-button-toggle-group>
    <div>
      <a class="btn-yellow btn-medium mr-2.5" routerLink="/user-management/add-new-user">
        <mat-icon>add</mat-icon>
        Add New User</a
      >
      <a class="btn-green btn-medium" routerLink="/dashboard/add-new-site">
        <mat-icon>add</mat-icon>
        Add New Building</a
      >
    </div>
  </div>

  <mat-card-title class="card-title-small">Client {{ detailsType === 'sites' ? 'Sites' : 'Users' }}</mat-card-title>

  <div *ngIf="detailsType === 'sites'">
    <app-site-table-desktop-filters />
    <app-site-table [isInjected]="true" />
    <div class="card-separator"></div>
  </div>
  <app-users-table [isInjected]="true" *ngIf="detailsType === 'users'" />
  <div class="card-separator"></div>
</ng-container>

<ng-template #skeletonAnimation>
  <app-skeleton-page-header />
  <ngx-skeleton-loader [theme]="{ height: '400px' }" />
  <app-skeleton-table />
</ng-template>
