import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { allClientDevicesLoaded, loadAllClientDevices, loadDevicesError, resetDeviceEntity } from './devices.actions';
import { DeviceAttribute, DeviceData, DeviceFullModel, DeviceManufacturer, DeviceModel } from '@models';
import { DevicesActions } from '../action-types';

export interface DevicesState extends EntityState<DeviceData> {
  allDevicesLoaded: boolean;
  spaceDeviceIsLoading: boolean;
  manufacturers: DeviceManufacturer[];
  models: DeviceModel[];
  attributes: DeviceAttribute[];
  fullModel?: DeviceFullModel;
  isDeviceCreating: boolean;
  isDeviceUpdating: boolean;
  isManufacturerCreating: boolean;
  isDeviceModelCreating: boolean;
  isModelImageUploading: boolean;
  modelImageUploadingError: any;
  isNewAttributeCreating: boolean;
}

export const devicesAdapter = createEntityAdapter<DeviceData>({});

export const initialDevicesState = devicesAdapter.getInitialState({
  allDevicesLoaded: false,
  spaceDeviceIsLoading: false,
  manufacturers: [] as DeviceManufacturer[],
  models: [] as DeviceModel[],
  attributes: [] as DeviceAttribute[],
  isDeviceCreating: false,
  isDeviceUpdating: false,
  isManufacturerCreating: false,
  isDeviceModelCreating: false,
  isModelImageUploading: false,
  modelImageUploadingError: null,
  isNewAttributeCreating: false,
});

export const devicesReducer = createReducer(
  initialDevicesState,
  on(loadAllClientDevices, state => {
    return {
      ...state,
    };
  }),
  on(resetDeviceEntity, state => {
    return devicesAdapter.setAll([], {
      ...state,
      allDevicesLoaded: false,
    });
  }),
  on(allClientDevicesLoaded, (state, action) => {
    return devicesAdapter.upsertMany(action.devices, {
      ...state,
      allDevicesLoaded: true,
    });
  }),

  on(loadDevicesError, state => {
    return {
      ...state,
    };
  }),

  on(DevicesActions.loadDeviceDetailsSuccess, (state, action) => {
    return devicesAdapter.setOne(action.deviceItem, { ...state });
  }),

  on(DevicesActions.addNewDevice, state => ({ ...state, isDeviceCreating: true })),

  on(DevicesActions.addNewDeviceSuccess, (state, action) =>
    devicesAdapter.addOne(action.newDevice, { ...state, isDeviceCreating: false })
  ),

  on(DevicesActions.addNewDeviceError, state => ({ ...state, isDeviceCreating: false })),

  on(DevicesActions.updateDeviceData, state => ({ ...state, isDeviceUpdating: true })),

  on(DevicesActions.deviceDataSuccessfullyUpdated, (state, action) => {
    return devicesAdapter.updateOne(
      {
        id: action.device.id,
        changes: action.device,
      },
      {
        ...state,
        isDeviceUpdating: false,
      }
    );
  }),

  on(DevicesActions.updateDeviceDataError, state => ({ ...state, isDeviceUpdating: false })),

  on(DevicesActions.loadDeviceCollectionBySpaceId, state => {
    return {
      ...state,
      spaceDeviceIsLoading: true,
    };
  }),

  on(DevicesActions.loadDeviceCollectionBySpaceIdSuccess, (state, actions) => {
    return devicesAdapter.upsertMany(actions.devices, { ...state, spaceDeviceIsLoading: false });
  }),
  on(DevicesActions.loadDeviceCollectionsSuccess, (state, actions) => {
    return { ...state, manufacturers: actions.manufacturers, models: actions.models, attributes: actions.attributes };
  }),
  on(DevicesActions.loadDeviceFullModelSuccess, (state, action) => {
    return { ...state, fullModel: action.fullModel };
  }),
  on(DevicesActions.addNewManufacturer, state => {
    return { ...state, isManufacturerCreating: true };
  }),
  on(DevicesActions.addNewManufacturerSuccess, (state, action) => {
    return { ...state, manufacturers: [...state.manufacturers, action.manufacturer], isManufacturerCreating: false };
  }),
  on(DevicesActions.addNewManufacturerError, state => {
    return { ...state, isManufacturerCreating: false };
  }),
  on(DevicesActions.addNewDeviceModel, state => {
    return { ...state, isDeviceModelCreating: true };
  }),
  on(DevicesActions.addNewDeviceModelSuccess, (state, action) => {
    return { ...state, models: [...state.models, action.deviceModel], isDeviceModelCreating: false };
  }),
  on(DevicesActions.addNewDeviceModelError, state => {
    return { ...state, isDeviceModelCreating: false };
  }),
  on(DevicesActions.uploadDeviceModelImage, state => {
    return { ...state, isModelImageUploading: true, modelImageUploadingError: null };
  }),
  on(DevicesActions.uploadDeviceModelImageSuccess, (state, action) => {
    return {
      ...state,
      isModelImageUploading: false,
      models: state.models.map(model => {
        return model.id === action.deviceModelId ? { ...model, documentReference: action.document.fileName } : model;
      }),
    };
  }),
  on(DevicesActions.uploadDeviceModelImageError, (state, action) => {
    return { ...state, isModelImageUploading: false, modelImageUploadingError: action.error };
  }),
  on(DevicesActions.addNewAttribute, state => {
    return { ...state, isNewAttributeCreating: true };
  }),
  on(DevicesActions.addNewAttributeSuccess, (state, action) => {
    return { ...state, isNewAttributeCreating: false, attributes: [...state.attributes, action.attribute] };
  }),
  on(DevicesActions.addNewAttributeError, state => {
    return { ...state, isNewAttributeCreating: false };
  })
);
