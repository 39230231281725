import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { SiteWidgetComponent } from './components/site-widget/site-widget.component';
import { MatIconModule } from '@angular/material/icon';
import { AddSiteDialogComponent } from './components/add-site-dialog/add-site-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AddNewSiteComponent } from './containers/add-new-site/add-new-site.component';
import { MatStepperModule } from '@angular/material/stepper';
import { NewSiteFormComponent } from './components/new-site-form/new-site-form.component';
import { AddFloorsComponent } from './components/add-floors/add-floors.component';
import { FloorItemComponent } from './components/floor-item/floor-item.component';
import { AddRoomsComponent } from './components/add-rooms/add-rooms.component';
import { RoomItemComponent } from './components/room-item/room-item.component';
import { ExpandableFloorItemComponent } from './components/expandable-floor-item/expandable-floor-item.component';
import { AttachDeviceComponent } from './components/attach-device/attach-device.component';
import { AttachDeviceItemComponent } from './components/attach-device-item/attach-device-item.component';
import { BuildingInfoComponent } from './components/building-info/building-info.component';
import { BuildingManagementPageComponent } from './containers/building-management-page/building-management-page.component';
import { BuildingPlanVisualModeComponent } from './components/building-plan-visual-mode/building-plan-visual-mode.component';
import { PipesModule } from '@pipes';
import { AddClientDialogComponent } from './components/add-client-dialog/add-client-dialog.component';
import { SiteHistoryTableComponent } from './components/site-history-table/site-history-table.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TippyDirective } from '@ngneat/helipopper';
import { BuildingPlanIncidentsComponent } from './components/building-plan-incidents/building-plan-incidents.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DashboardMainComponent } from '@app/modules/dashboard/containers/dashboard-main/dashboard-main.component';
import { EditSiteComponent } from './containers/edit-site/edit-site.component';
import { HandleRoomsComponent } from './components/handle-rooms/handle-rooms.component';
import { addDeviceFormResolver, locationsResolver, locationStateResolver } from '@ngrx-store';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@directives';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SpaceDetailsComponent } from '@app/modules/dashboard/containers/space-details/space-details.component';
import { EditFloorComponent } from './containers/edit-floor/edit-floor.component';
import { EditRoomComponent } from './containers/edit-room/edit-room.component';
import { EditRoomDialogComponent } from './components/edit-room-dialog/edit-room-dialog.component';
import { DashboardLocationHeaderComponent } from './components/dashboard-location-header/dashboard-location-header.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DeviceListComponent } from './components/device-list/device-list.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { SkeletonPageHeaderComponent } from '@standalone/skeleton-page-header/skeleton-page-header.component';
import { MainInputFormComponent } from '@standalone/main-input-form/main-input-form.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { BreadcrumbsComponent } from '@standalone/breadcrumbs/breadcrumbs.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { DashboardMapComponent } from '@standalone/dashboard-map.component/dashboard-map.component';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';
import { DeleteSpaceplanComponent } from '@standalone/_spaceplan/delete-spaceplan/delete-spaceplan.component';
import { SpaceplanComponent } from '@standalone/_spaceplan/spaceplan/spaceplan.component';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { DeviceStatusCountComponent } from '@standalone/device-status-count/device-status-count.component';
import { IncidentStatusCountComponent } from '@standalone/incident-status-count/incident-status-count.component';
import { NewDeviceFormComponent } from '@standalone/new-device-form/new-device-form.component';
import { SiteTableDesktopFiltersComponent } from '@standalone/_tables/site-table-desktop-filters/site-table-desktop-filters.component';
import { SiteTableComponent } from '@standalone/_tables/site-table/site-table.component';
import { IncidentsTableDesktopFiltersComponent } from '@standalone/_tables/incidents-table-desktop-filters/incidents-table-desktop-filters.component';
import { GooglePlaceAutocompleteComponent } from '@standalone/google-place-autocomplete/google-place-autocomplete.component';
import { DevicesTableComponent } from '@standalone/_tables/devices-table/devices-table.component';
import { DevicesTableDesktopFiltersComponent } from '@standalone/_tables/devices-table-desktop-filters/devices-table-desktop-filters.component';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';
import { RoomListTableComponent } from '@standalone/_tables/room-list-table/room-list-table.component';
import { RoleGuard } from '@app-lib';
import { UploadSpaceplanComponent } from '@standalone/_spaceplan/upload-spaceplan/upload-spaceplan.component';
import { RoomDetailsComponent } from './containers/room-details/room-details.component';

@NgModule({
  declarations: [
    DashboardMainComponent,
    SiteWidgetComponent,
    AddSiteDialogComponent,
    AddNewSiteComponent,
    NewSiteFormComponent,
    AddFloorsComponent,
    FloorItemComponent,
    AddRoomsComponent,
    ExpandableFloorItemComponent,
    RoomItemComponent,
    AttachDeviceComponent,
    AttachDeviceItemComponent,
    BuildingInfoComponent,
    BuildingManagementPageComponent,
    BuildingPlanVisualModeComponent,
    AddClientDialogComponent,
    SiteHistoryTableComponent,
    BuildingPlanIncidentsComponent,
    EditSiteComponent,
    HandleRoomsComponent,
    SpaceDetailsComponent,
    EditFloorComponent,
    EditRoomComponent,
    EditRoomDialogComponent,
    DashboardLocationHeaderComponent,
    DeviceListComponent,
    RoomDetailsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: DashboardMainComponent,
        resolve: {
          locations: locationsResolver,
        },
      },
      {
        path: 'add-new-site',
        component: AddNewSiteComponent,
        data: { breadcrumb: 'Add New Building' },
        canActivate: [RoleGuard],
        resolve: {
          addDeviceFormResolver,
        },
      },
      {
        path: ':locationId',
        component: BuildingManagementPageComponent,
        resolve: { locationStateResolver },
      },
      {
        path: ':locationId/edit',
        component: EditSiteComponent,
        canActivate: [RoleGuard],
        resolve: { locationStateResolver },
      },
      {
        path: ':locationId/:floorId',
        component: SpaceDetailsComponent,
        resolve: { locationStateResolver },
      },
      {
        path: ':locationId/:floorId/edit',
        component: EditFloorComponent,
        resolve: { locationStateResolver },
      },
      {
        path: ':locationId/:floorId/:roomId',
        component: RoomDetailsComponent,
        resolve: { locationStateResolver },
      },
      {
        path: ':locationId/:floorId/:roomId/edit',
        component: EditRoomComponent,
        resolve: { locationStateResolver },
      },
    ]),
    GoogleMapsModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatButtonToggleModule,
    MatStepperModule,
    PipesModule,
    MatProgressSpinnerModule,
    TippyDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    DirectivesModule,
    MatSlideToggleModule,
    NgxSkeletonLoaderModule,
    SkeletonTableComponent,
    SkeletonPageHeaderComponent,
    MainInputFormComponent,
    SearchInputComponent,
    BreadcrumbsComponent,
    EntityStatusComponent,
    DashboardMapComponent,
    MultipleSelectComponent,
    DeleteSpaceplanComponent,
    SpaceplanComponent,
    NoDataComponent,
    PageHeaderComponent,
    DeviceStatusCountComponent,
    IncidentStatusCountComponent,
    NewDeviceFormComponent,
    SiteTableDesktopFiltersComponent,
    SiteTableComponent,
    IncidentsTableDesktopFiltersComponent,
    GooglePlaceAutocompleteComponent,
    DevicesTableComponent,
    DevicesTableDesktopFiltersComponent,
    IncidentsTableComponent,
    RoomListTableComponent,
    UploadSpaceplanComponent,
  ],
})
export class DashboardModule {}
