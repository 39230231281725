import { Component, EventEmitter, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DeviceData, DeviceLocation, EntityStatus, LocationFullData, RoomSpaceModel, SelectOption } from '@models';
import { AppState, getDeviceBySpaceId, locationState } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AttachmentDevicePopoverComponent } from '@standalone/attachment-popovers/attachment-device-popover/attachment-device-popover.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-attach-device',
  templateUrl: './attach-device.component.html',
})
export class AttachDeviceComponent {
  @Output() addNewDevice = new EventEmitter<DeviceLocation>();
  locationFullData: LocationFullData | undefined;
  floorOptions: SelectOption[] = [];
  roomOptions: SelectOption[] = [];
  selectedFloorId = '';
  selectedRoomId = '';
  selectedRoom: RoomSpaceModel | undefined;
  editDevicePopover = AttachmentDevicePopoverComponent;
  deviceList$: Observable<DeviceData[]> | undefined;
  deviceTab: 'list' | 'add' = 'list';
  deviceToEdit: DeviceData | null = null;

  constructor(private store: Store<AppState>) {
    this.store
      .select(locationState)
      .pipe(takeUntilDestroyed())
      .subscribe(location => {
        this.locationFullData = location;
        this.generateFloorSelectOptions();
        this.initRoom();
      });
  }

  generateFloorSelectOptions() {
    if (this.locationFullData?.floors) {
      this.floorOptions = this.locationFullData.floors
        .filter(({ status }) => status !== EntityStatus.Archived)
        .map(({ id, friendlyName, name }) => ({
          value: id,
          title: friendlyName || name,
        }));
    }
  }

  generateRoomSelectOptions() {
    if (this.locationFullData?.floors) {
      const currentFloor = this.locationFullData.floors.find(({ id }) => id === this.selectedFloorId);
      if (currentFloor?.rooms) {
        this.roomOptions = currentFloor.rooms
          .filter(({ status }) => status !== EntityStatus.Archived)
          .map(({ id, friendlyName, name }) => ({
            value: id,
            title: friendlyName || name,
          }));
      } else {
        this.roomOptions = [];
      }
    }
  }

  changeFloor($event: string) {
    this.selectedFloorId = $event;
    this.selectedRoomId = '';
    this.selectedRoom = undefined;
    this.generateRoomSelectOptions();
    this.deviceTab === 'list';
  }

  changeRoom($event: string) {
    this.selectedRoomId = $event;
    this.initRoom();
  }

  initRoom() {
    if (this.locationFullData?.floors) {
      const currentFloor = this.locationFullData.floors.find(({ id }) => id === this.selectedFloorId);
      if (currentFloor?.rooms) {
        const room = currentFloor.rooms.find(({ id }) => id === this.selectedRoomId);
        if (room) {
          this.selectedRoom = room;
          this.deviceList$ = this.store.select(getDeviceBySpaceId(room.id));
        }
      }
    }
  }

  tabChange(change: MatButtonToggleChange) {
    this.deviceTab = change.value;
    if (this.deviceTab === 'list') {
      this.deviceToEdit = null;
    }
  }

  editDevice(device: DeviceData) {
    this.deviceToEdit = device;
    this.deviceTab = 'add';
  }
}
