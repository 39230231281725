import { Component, OnDestroy, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IncidentColumn } from '@app-lib';
import { DeviceTableCols, IncidentModel } from '@models';
import { AppState, deviceLoaded, selectAllDevices } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { DevicesFilterService, IncidentsFilterService } from '@services';
import { Observable } from 'rxjs';
import { DevicesTableComponent } from '@standalone/_tables/devices-table/devices-table.component';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';

@Component({
  selector: 'app-reporting-main-page',
  templateUrl: './reporting-main-page.component.html',
})
export class ReportingMainPageComponent implements OnDestroy {
  @ViewChild(IncidentsTableComponent) incidentsTable!: IncidentsTableComponent;
  incidents: IncidentModel[] | null = null;
  deviceListLoaded$!: Observable<boolean>;
  @ViewChild(DevicesTableComponent) deviceTable!: DevicesTableComponent;
  reportingType: 'incident' | 'firmware' = 'incident';
  incidentDisplayedColumns = [
    IncidentColumn.INDEX,
    IncidentColumn.TICKET_NUMBER,
    IncidentColumn.STATUS,
    IncidentColumn.DEVICE_ID,
    IncidentColumn.DEVICE_TYPE,
    IncidentColumn.BUILDING,
    IncidentColumn.FLOOR,
    IncidentColumn.ROOM,
    IncidentColumn.REPORTED,
    IncidentColumn.RESOLVED,
    IncidentColumn.FAULT_NAME,
  ];

  firmwareDisplayedColumns = [
    DeviceTableCols.FIRMWARE,
    DeviceTableCols.DEVICE_TYPE,
    DeviceTableCols.BUILDING,
    DeviceTableCols.FLOOR,
    DeviceTableCols.ROOM,
    DeviceTableCols.MANUFACTURER,
    DeviceTableCols.MODEL,
    DeviceTableCols.SERIAL_NUMBER,
  ];

  constructor(
    private store: Store<AppState>,
    public devicesFilterService: DevicesFilterService,
    public incidentsFilterService: IncidentsFilterService
  ) {
    this.store
      .select(selectAllDevices)
      .pipe(takeUntilDestroyed())
      .subscribe(devices => {
        this.devicesFilterService.isReportMode = true;
        this.devicesFilterService.initColumns(this.firmwareDisplayedColumns);
        this.devicesFilterService.initDevices(devices);
      });
    this.deviceListLoaded$ = this.store.select(deviceLoaded);
    this.incidentsFilterService.displayedColumns = this.incidentDisplayedColumns;
  }

  ngOnDestroy() {
    this.devicesFilterService.resetState();
    this.incidentsFilterService.resetState();
  }

  exportDevices(type: 'json' | 'csv') {
    if (type === 'json') {
      this.deviceTable.exportDevicesToJSON();
    }
    if (type === 'csv') {
      this.deviceTable.exportDevicesToCSV();
    }
  }

  exportIncidents(type: 'json' | 'csv') {
    if (type === 'json') {
      this.incidentsTable.exportIncidentsToJSON();
    }
    if (type === 'csv') {
      this.incidentsTable.exportIncidentsToCSV();
    }
  }

  setIncidents(incidents: IncidentModel[] | null) {
    this.incidents = incidents;
  }
}
