import { Component, DestroyRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeviceData, EntityStatus, SelectOption } from '@models';
import { AppState, getDeviceBySpaceId, loadDeviceCollectionBySpaceId, updateDeviceData } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { AppService } from '@services';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { isElementInViewport } from '@app-lib';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styles: [],
})
export class DeviceListComponent implements OnChanges {
  @Input() roomId = '';
  @Input() locationId = '';
  @Input() roomplan: string | undefined;
  @Input() showHeader = true;
  @Output() editDevice = new EventEmitter<DeviceData>();
  deviceStatus: 'current' | 'archived' = 'current';
  deviceSearch = '';
  deviceOptions: SelectOption[] = [{ value: '', title: 'Select device' }];
  selectedDevice = new FormControl();
  deviceList$: Observable<DeviceData[]> | undefined;
  entityStatus = EntityStatus;
  destroyRef = inject(DestroyRef);

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
    private appService: AppService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['roomId'] || changes['locationId']) {
      if ((this.locationId, this.roomId)) {
        this.store.dispatch(loadDeviceCollectionBySpaceId({ locationId: this.locationId, roomId: this.roomId }));
        this.deviceList$ = this.store.select(getDeviceBySpaceId(this.roomId));
      }
    }
  }

  deviceSearchChanged(searchValue: string) {
    this.deviceSearch = searchValue;
  }

  onDeviceStatusFilterChange(change: MatButtonToggleChange) {
    this.deviceStatus = change.value;
  }

  get editDeviceId() {
    return this.appService.activeAttachmentId;
  }

  assignDevice(id: string) {
    this.appService.activeAttachmentId = id;

    const element = document.querySelector('app-spaceplan');

    if (element && element instanceof HTMLElement && !isElementInViewport(element)) {
      scrollIntoView(element, { behavior: 'smooth', block: 'start' });
    }
  }

  removeCoordinates(device: DeviceData) {
    this.openModal({
      title: 'Unassign a Device',
      description: `Are you sure you want to unassign a device [${device.friendlyName}] from the block diagram?`,
      data: { unstructuredDataReference: '' },
      id: device.id,
    });
  }

  updateDevice(id: string, data: Partial<DeviceData>) {
    this.store.dispatch(
      updateDeviceData({
        locationId: this.locationId,
        deviceId: id,
        data,
      })
    );
  }

  openModal({
    title,
    description,
    data,
    id,
  }: {
    title: string;
    description: string;
    id: string;
    data: Partial<DeviceData>;
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.updateDevice(id, data);
        }
      });
  }

  archiveDevice(device: DeviceData) {
    this.openModal({
      title: 'Archive device',
      description: `Are you sure you want to archive device [${device.friendlyName}]?`,
      id: device.id,
      data: { status: EntityStatus.Archived },
    });
  }

  restoreDevice(device: DeviceData) {
    this.openModal({
      title: 'Restore device',
      description: `Are you sure you want to restore device [${device.friendlyName}]?`,
      id: device.id,
      data: { status: EntityStatus.Active },
    });
  }

  editDeviceAction(device: DeviceData) {
    this.editDevice.emit(device);
  }
}
