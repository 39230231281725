<div class="flex justify-between items-center mb-4">
  <app-page-header>Users Management</app-page-header>
  <a appRestrictedEl [showForClientAdmin]="true" class="btn-green btn-medium" routerLink="add-new-user">
    <mat-icon fontIcon="add" />
    Add New User
  </a>
</div>
<mat-card class="card py-0 shadow-card">
  <mat-card-content class="py-6">
    <app-users-table />
  </mat-card-content>
</mat-card>
