import { Component, Input } from '@angular/core';
import { DirectivesModule } from '@directives';

@Component({
  selector: 'app-upload-spaceplan',
  templateUrl: './upload-spaceplan.component.html',
  standalone: true,
  imports: [DirectivesModule],
})
export class UploadSpaceplanComponent {
  @Input() locationId = '';
  @Input() floorId = '';
  @Input() roomId = '';
  @Input() uploadText = 'Upload File';
}
