<mat-card class="card pb-6">
  <mat-card-title class="card-title">Building Incidents </mat-card-title>
  <mat-card-content class="p-0">
    <app-incidents-table-desktop-filters
      [filtersEnabled]="true"
      [resolvedIncidentsLoading]="resolvedIncidentsLoading" />
    <app-incidents-table
      [isInjected]="true"
      [dataIsLoading]="resolvedIncidentsLoading"
      noDataText="All the Building devices are working properly" />
  </mat-card-content>
</mat-card>
