@let locationsLoaded = locationsLoaded$ | async ; @if (locationsLoaded === false) {
<ngx-skeleton-loader count="10" [theme]="{ marginBottom: '5px', height: '30px' }" />
} @else {
<cdk-virtual-scroll-viewport
  appVirtualTableScrollNormalizer
  [changeTrigger]="dataSource"
  itemSize="1"
  [ngClass]="{ 'fixed-scroll': isInjected }">
  <table
    matSortActive="friendlyName"
    matSortDirection="asc"
    mat-table
    [dataSource]="dataSource"
    id="sites-table"
    class="main-table"
    matSort>
    <!-- Number Column -->
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef>№</th>
      <td mat-cell *matCellDef="let index = index">
        {{ index + 1 }}
      </td>
    </ng-container>

    <!-- Building Name Column -->
    <ng-container matColumnDef="friendlyName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by building name">
        Building Name
      </th>
      <td mat-cell *matCellDef="let element">
        <a class="underline" [routerLink]="['/dashboard', element.id]">{{ element.friendlyName }}</a>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Status</th>
      <td mat-cell *matCellDef="let element">
        <app-entity-status [status]="element.status" />
      </td>
    </ng-container>

    <!-- Address Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by address">Address</th>
      <td mat-cell *matCellDef="let element">
        {{ element.address.streetAddress }}, {{ element.address.city }}, {{ element.address.stateProvince }},
        {{ element.address.postalCode }}, {{ element.address.country }}
      </td>
    </ng-container>

    <!-- Incident Column -->
    <ng-container matColumnDef="incidents">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Incidents</th>
      <td mat-cell *matCellDef="let element">
        <ngx-skeleton-loader
          *ngIf="(isLocationsSummariesLoading$ | async) === true"
          [theme]="{ height: '36px', marginBottom: 0, verticalAlign: 'top' }" />
        <ng-container
          *ngIf="element.status === entityStatus.Active && (isLocationsSummariesLoading$ | async) === false">
          <app-incident-status-count
            *ngIf="element.incidentCountByStatuses?.newCount"
            [status]="incidentStatus.IN_QUEUE"
            [amount]="element.incidentCountByStatuses.newCount"
            class="mr-1" />
          <app-incident-status-count
            *ngIf="element.incidentCountByStatuses?.inProgressCount"
            [status]="incidentStatus.IN_PROGRESS"
            [amount]="element.incidentCountByStatuses.inProgressCount" />
        </ng-container>
      </td>
    </ng-container>

    <!-- Device Status Column -->
    <ng-container matColumnDef="deviceStatus">
      <th mat-header-cell *matHeaderCellDef>Devices</th>
      <td mat-cell *matCellDef="let element">
        <ngx-skeleton-loader
          *ngIf="(isLocationsSummariesLoading$ | async) === true"
          [theme]="{ height: '36px', marginBottom: 0, verticalAlign: 'top' }" />
        <ng-container
          *ngIf="element.status === entityStatus.Active && (isLocationsSummariesLoading$ | async) === false">
          <app-device-status-count
            *ngIf="element.devicesByStatuses"
            [activeAmount]="element.devicesByStatuses.activeCount"
            [pausedAmount]="element.devicesByStatuses.pausedCount" />
        </ng-container>
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th
        mat-header-cell
        *matHeaderCellDef
        appRestrictedEl
        [showForClientAdmin]="buildingDataFilterService.buildingStatusFilter === 'current'"></th>
      <td
        mat-cell
        *matCellDef="let element"
        appRestrictedEl
        [showForClientAdmin]="buildingDataFilterService.buildingStatusFilter === 'current'">
        <ng-container *ngIf="element.status !== entityStatus.Archived">
          <a
            appRestrictedEl
            class="mr-2"
            [matTooltip]="'Edit Building'"
            [routerLink]="['/dashboard', element.id, 'edit']">
            <i class="icon-edit action-icon"></i>
          </a>
          <i
            class="icon-play-16 action-icon test-resume-btn"
            appRestrictedEl
            [showForClientAdmin]="true"
            [matTooltip]="'Resume'"
            *ngIf="element.status === entityStatus.Paused"
            (click)="resumeSite(element)"></i>
          <i
            class="icon-pause-16 action-icon"
            [matTooltip]="'Pause'"
            appRestrictedEl
            [showForClientAdmin]="true"
            *ngIf="element.status === entityStatus.Active"
            (click)="pauseSite(element)"></i>
          <i
            class="icon-add-to-archive action-icon test-pause-btn"
            [matTooltip]="'Archive'"
            appRestrictedEl
            (click)="archiveSite(element)"></i>
        </ng-container>
        <i
          [matTooltip]="'Restore'"
          appRestrictedEl
          *ngIf="element.status === entityStatus.Archived"
          class="action-icon icon-restore"
          (click)="restoreSite(element)"></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.is-offline]="row.status === entityStatus.Paused"></tr>
  </table>
</cdk-virtual-scroll-viewport>
<app-no-data
  *ngIf="!dataSource.filteredData.length && (locationsLoaded$ | async) === true"
  [resultContent]="!!buildingDataFilterService.search"
  noDataText="No buildings yet" />
}
